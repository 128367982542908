<template>
  <div class="flex flex-col items-center">
    <div class="max-w-5xl lg:w-full w-5/6 lg:mx-0 mx-auto">

      <h2 class="text-2xl font-semibold py-4 border-b-2 border-red-600">
        Super Compensation
      </h2>

      <img
        src="../../assets/img/tools_super_compensation.jpg"
        alt="Super Compensation Banner"
        class="object-cover w-full lg:h-96 h-80 mt-4"
      >
        <p class="py-4">
          Super Comp is a manifestation of the superintending of pay trends.
          It is powered by MRC machine learning algorithm which acts as an arbitrator to
          evaluate massive amount of data (more than 270,000 data from around 15,000 companies),
          both unstructured and structured, compelling to bring you the recommended pay
          and bonuses and their movements around the world.
        </p>
      <img
        src="../../assets/img/tools_super_compensation2.jpg"
        alt="CSuper Compensation Chart"
        class="object-cover w-full lg:w-3/4 md:w-4/5 sm:p-4 content-center"
      >
      <br>
      <br>
        <p class="py-1">
          Some of your considerations may include:
        </p>
        <ul class="px-4 pb-4 list-disc text-red-700">
          <li>Global compensation database for all levels of jobs (Up to Total Cash)</li>
          <li>Unlimited access subject to data contribution by countries & jobs</li>
        </ul>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
export default {};
</script>
